export default {
    name: "TutorialVideos",
    data() {
        return {
            currentVideo: {},
            frameLoading: false,
        }
    },
    computed: {
        videos() {
            // youtube 需要https://youtube.com/embed/  这样才能使用
            let link = {
                zh: ['https://www.313fm.com/course/313fm-1.mp4', 'https://www.313fm.com/course/313fm-2.mp4', 'https://www.313fm.com/course/313fm-3.mp4', 'https://www.313fm.com/course/313fm-4.mp4', 'https://www.313fm.com/course/313fm-5.mp4'],
                zht: ['https://youtube.com/embed/cDFRAKzx43c', 'https://youtube.com/embed/H93zMhR5_uk', 'https://youtube.com/embed/Q8OodFJ3Zwo', 'https://youtube.com/embed/p2RW70rUJB0', 'https://youtube.com/embed/7hnqckHJodU'],
                en: ['https://youtube.com/embed/e5NeVNoXO4Y', 'https://youtube.com/embed/S2Q9Z4bLZ4Q', 'https://youtube.com/embed/LT4XzXOrYJw', 'https://youtube.com/embed/p0WIJOY3KYw', 'https://youtube.com/embed/RE42Hi0o2kQ'],
            }
            let otherLink = {
                zh: ['https://www.313fm.com/course/313fm-6-part1.mp4', 'https://www.313fm.com/course/313fm-6-part2.mp4', 'https://www.313fm.com/course/313fm-WorkOrderManual-zh.mp4'],
                zht: ['https://youtube.com/embed/fRs_J5cpcqU', 'https://youtube.com/embed/D6IN9LUk7r0', 'https://youtube.com/embed/cLuWOFen7lI'],
                en: ['https://youtube.com/embed/KGnHGfdOhV8', 'https://youtube.com/embed/_TJaGb83CyE', 'https://youtube.com/embed/-5_AM6e7cps'],
            }
            let lang = this.$store.getters.lang || 'zht'
            if (lang === 'tw') {
                lang = 'zht'
            }
            return ({
                basic: {
                    title: this.$t('tutorialVideo.basic'),
                    list: [
                        {
                            label: this.$t('tutorialVideo.basic_video1'),
                            link: link[lang][0],
                            poster: require('@/assets/tutorial_poster/zh/basic_1.png'),
                        }, {
                            label: this.$t('tutorialVideo.basic_video2'),
                            link: link[lang][1],
                            poster: require('@/assets/tutorial_poster/zh/basic_2.png'),
                        }, {
                            label: this.$t('tutorialVideo.basic_video3'),
                            link: link[lang][2],
                            poster: require('@/assets/tutorial_poster/zh/basic_3.png'),
                        }, {
                            label: this.$t('tutorialVideo.basic_video4'),
                            link: link[lang][3],
                            poster: require('@/assets/tutorial_poster/zh/basic_4.png'),
                        }, {
                            label: this.$t('tutorialVideo.basic_video5'),
                            link: link[lang][4],
                            poster: require('@/assets/tutorial_poster/zh/basic_5.png'),
                        },
                    ]
                },
                other: {
                    title: this.$t('tutorialVideo.other'),
                    list: [
                        {
                            label: this.$t('tutorialVideo.other_video1'),
                            link: otherLink[lang][0],
                            poster: require('@/assets/tutorial_poster/zh/more_1.png'),
                        }, {
                            label: this.$t('tutorialVideo.other_video2'),
                            link: otherLink[lang][1],
                            poster: require('@/assets/tutorial_poster/zh/more_2.png'),
                        }, {
                            label: this.$t('tutorialVideo.other_video3'),
                            link: otherLink[lang][2],
                            poster: require('@/assets/tutorial_poster/zh/more_3.png'),
                        }
                    ]
                }

            })
        }
    },
    mounted() {
        this.currentVideo = this.videos.basic.list[0]
        this.loadFrame()
    },
    methods: {
        videoClick(item) {
            this.currentVideo = item
            this.loadFrame()
        },
        // 语言切换
        langChange() {
            this.currentVideo = this.videos.basic.list[0]
            this.loadFrame()
            if (this.$store.getters.lang === 'zh') {
                this.frameLoading = false
            }
        },
        // 加载frame
        loadFrame() {
            // 如果是简体中文，就终止往下执行
            if (this.$store.getters.lang === 'zh') return
            this.frameLoading = true
            console.log([this.$refs.iframeRef])
            this.$refs.iframeRef.onload = (e) => {
                console.log('onload', e)
                this.frameLoading = false
            }
            this.$refs.iframeRef.onerror = (e) => {
                console.log('error', e)
                this.frameLoading = false
            }
            this.$refs.iframeRef.onended = (e) => {
                console.log('onended', e)
                this.frameLoading = false
            }
            // this.$refs.iframeRef.onplayerror = (e) => {
            //     console.log('error', e)
            //     this.frameLoading = false
            // }
        }
    }
}